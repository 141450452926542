import Axios from "axios";

import Config from '../Config';

class APIService {
    key = "";
    hostname = null;

    constructor(key = "", hostname = Config.Host) {
        this.key = key;
        this.hostname = hostname;
    }

    get axiosConfig() {
        return {
            headers: {
                Authorization: "Bearer " + this.key
            }
        }
    }

    async verifyKey() {
        const response = await Axios.get(this.hostname + 'verify', this.axiosConfig);

        return response.data;
    }

    async getList() {
        const response = await Axios.get(this.hostname + 'list', this.axiosConfig);

        return response.data;
    }

    async getExpiredList() {
        const response = await Axios.get(this.hostname + 'list/expired', this.axiosConfig);

        return response.data;
    }

    async getLogs(levels) {
        let params = '';

        levels.forEach(l => {
            if (params === '') {
                params += '?';
            } else {
                params += '&';
            }

            params += 'level=' + l;
        });

        const response = await Axios.get(this.hostname + 'log' + params, this.axiosConfig);

        return response.data;
    }

    async addConfiguration(configuration) {
        const response = await Axios.post(this.hostname + 'add', configuration, this.axiosConfig);

        return response.data;
    }

    async editConfiguration(configuration) {
        const response = await Axios.post(this.hostname + 'edit', configuration, this.axiosConfig);

        return response.data;
    }

    async deleteConfiguration(id, expired) {
        const response = await Axios.delete(this.hostname + 'remove/' + (expired ? 'expired/' : '') + id, this.axiosConfig);

        return response.data;
    }
}

export default APIService;