import React from 'react';
import Moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import DateInputButton from './DateInputButton';

import { Alert, Button, Col, Form, Modal, Table } from 'react-bootstrap';
import Config from '../Config';

require('moment/locale/pt');

export default class AddConfigurationModal extends React.Component {
    constructor() {
        super();

        this.state = {
            name: '',
            url: '',
            contents: '',
            method: 'GET',
            date: new Date(),
            timesDay: 250,
            requestDataType: 'application/x-www-form-urlencoded',
            requestDataTypeOther: '',
            headers: [],
            pauseStart: '00:30',
            pauseEnd: '08:30',
            error: null
        };
    }

    intToTimePartString(int) {
        if (int >= 10)
            return '' + int;
        
        return '0' + int;
    }

    componentDidMount() {
        if (this.props.configuration) {
            const configuration = this.props.configuration;
            const content = configuration.Request.Content;

            let requestDataType = '';
            let requestDataTypeOther = '';

            if (content) {
                switch (content.Type) {
                    case 'application/x-www-form-urlencoded':
                        requestDataType = 'application/x-www-form-urlencoded';
                        requestDataTypeOther = '';

                        break;

                    case 'application/json':
                        requestDataType = 'application/json';
                        requestDataTypeOther = '';

                        break;

                    default:
                        requestDataType = 'other';
                        requestDataTypeOther = content.Type;

                        break;
                }
            }

            let pauseStart = Config.InitialPauseStart;
            let pauseEnd = Config.InitialPauseEnd;

            if (configuration.PauseTime != null) {
                pauseStart = this.intToTimePartString(configuration.PauseTime.StartHours) + ':' + this.intToTimePartString(configuration.PauseTime.StartMinutes);
            }

            if (configuration.PauseTime != null) {
                pauseEnd = '' + this.intToTimePartString(configuration.PauseTime.EndHours) + ':' + this.intToTimePartString(configuration.PauseTime.EndMinutes);
            }

            this.setState({
                name: configuration.Name,
                url: configuration.Request.URL,
                contents: content ? content.Value : '',
                method: configuration.Request.Method,
                date: Moment(configuration.EndTime).toDate(),
                timesDay: configuration.TimesPerDay,
                requestDataType,
                requestDataTypeOther,
                pauseStart,
                pauseEnd,
                headers: configuration.Request.Headers,
                error: null
            });
        }
    }

    async submit() {
        try {
            const request = {
                URL: this.state.url,
                Method: this.state.method,
                Headers: this.state.headers
            }
    
            if (this.state.method !== 'GET') {
                request['Content'] = {
                    Type: this.state.requestDataType === 'other' ? this.state.requestDataTypeOther : this.state.requestDataType,
                    Value: this.state.contents
                };
            }
    
            let pauseTime = null;
    
            if (this.state.pauseStart !== '' && this.state.pauseEnd !== '') {
                pauseTime = {
                    StartHours: parseInt(this.state.pauseStart.split(':')[0]),
                    StartMinutes: parseInt(this.state.pauseStart.split(':')[1]),
                    EndHours: parseInt(this.state.pauseEnd.split(':')[0]),
                    EndMinutes: parseInt(this.state.pauseEnd.split(':')[1])
                };
            } else if (this.state.pauseStart !== '' || this.state.pauseEnd !== '') {
                this.setState({ error: { data: { errors: ['Either all or no fields related to pause time must be filled!'] } } });
    
                return;
            }
    
            if (this.props.editId) {
                await this.props.apiService.deleteConfiguration(this.props.editId, false);
    
                const expired = (await this.props.apiService.getExpiredList()).sort((a, b) => b.ID - a.ID);

                await this.props.apiService.deleteConfiguration(expired[0].ID, true);
            }
    
            const obj = {
                Request: request,
                PauseTime: pauseTime,
                TimesPerDay: parseInt(this.state.timesDay),
                Name: this.state.name,
                EndTime: this.state.date
            };

            await this.props.apiService.addConfiguration(obj);

            this.cleanForm();
            this.props.onHide();
        } catch (e) {
            console.log(e);

            this.setState({
                error: e.response
            });
        }
    }

    cleanForm() {
        this.setState({
            name: '',
            url: '',
            contents: '',
            method: 'GET',
            date: new Date(),
            timesDay: 250,
            pauseStart: Config.InitialPauseStart,
            pauseEnd: Config.InitialPauseEnd,
            requestDataType: 'application/x-www-form-urlencoded',
            requestDataTypeOther: '',
            headers: [],
            error: null
        });
    }

    handleHeaderNameChange(pos, newName) {
        let headers = this.state.headers;

        headers[pos].Name = newName;

        this.setState({
            headers
        });
    }

    handleHeaderValueChange(pos, newValue) {
        let headers = this.state.headers;

        headers[pos].Value = newValue;

        this.setState({
            headers
        });
    }

    handleHeaderDelete(pos) {
        let headers = this.state.headers;

        headers.splice(pos, 1);

        this.setState({
            headers
        });
    }

    addNewHeaderRow() {
        this.setState({
            headers: [...this.state.headers, { Name: '', Value: '' }]
        });

        return false;
    }

    render() {
        return (
            <Modal size="xl" backdrop="static" show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    { this.props.editId ? (<Modal.Title>Edit Configuration</Modal.Title>) : (<Modal.Title>Add Configuration</Modal.Title>) }
                </Modal.Header>
                <Modal.Body>
                    { this.state.error && (
                        <Alert variant='danger'>
                            <b>Error!</b> {this.state.error.status} {this.state.error.statusText}
                            <hr />
                            <ul>
                            {this.state.error.data && this.state.error.data.errors && this.state.error.data.errors.map(e => {
                                return <li>{e}</li>
                            })}
                            </ul>
                        </Alert>
                    ) }
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Play Stupid Games, Win Stupid Prizes" value={this.state.name} onChange={x => this.setState({ name: x.target.value })} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>URL</Form.Label>
                                <Form.Control type="text" placeholder="http://google.com/" value={this.state.url} onChange={x => this.setState({ url: x.target.value })} />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Method</Form.Label>
                                <Form.Control as="select" value={this.state.method} onChange={x => this.setState({ method: x.target.value }) }>
                                    <option value={'GET'}>GET</option>
                                    <option value={'POST'}>POST</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>

                        { this.state.method === 'POST' && (
                            <>
                                <Form.Group>
                                    <Form.Label>Request Contents</Form.Label>
                                    <Form.Control placeholder="foo=bar&bar=baz" value={this.state.contents} onChange={x => this.setState({ contents: x.target.value }) }/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Request Data Type</Form.Label>
                                    <Form.Control as="select" value={this.state.requestDataType} onChange={x => this.setState({ requestDataType: x.target.value }) }>
                                        <option value={'application/x-www-form-urlencoded'}>Form Data</option>
                                        <option value={'application/json'}>JSON Data</option>
                                        <option value='other'>Other</option>
                                    </Form.Control>
                                </Form.Group>
                                { this.state.requestDataType === 'other' && (
                                    <Form.Group>
                                        <Form.Label>Request Data Type (Other)</Form.Label>
                                        <Form.Control placeholder="text/html; charset=utf-8" value={this.state.requestDataTypeOther} onChange={x => this.setState({ requestDataTypeOther: x.target.value }) }/>
                                    </Form.Group>
                                ) }
                            </>
                        ) }

                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Times / Day</Form.Label>
                                <Form.Control placeholder="250" value={this.state.timesDay} onChange={x => this.setState({ timesDay: x.target.value })} />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Date</Form.Label>
                                <br />
                                <DatePicker
                                    selected={this.state.date}
                                    onChange={date => this.setState({ date })}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    minDate={new Date()}
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="dd MMM yyyy @ HH:mm"
                                    customInput={<DateInputButton />}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Pause Start</Form.Label>
                                <Form.Control type="time" value={this.state.pauseStart} onChange={x => this.setState({ pauseStart: x.target.value })} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Pause End</Form.Label>
                                <Form.Control type="time" value={this.state.pauseEnd} onChange={x => this.setState({ pauseEnd: x.target.value })} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridHeaders">
                                <Form.Label>Headers</Form.Label>
                                <br />
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Value</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.headers.map((h, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td><input className="form-control" type="text" value={h.Name} onChange={(e) => { this.handleHeaderNameChange(idx, e.target.value) }} /></td>
                                                    <td><input className="form-control" type="text" value={h.Value} onChange={(e) => { this.handleHeaderValueChange(idx, e.target.value) }} /></td>
                                                    <td><Button variant="outline-danger" size="sm" onClick={() => { this.handleHeaderDelete(idx) }}>Delete</Button></td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colSpan={3}><Button variant='link' onClick={this.addNewHeaderRow.bind(this)}>➕ Add New</Button></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.submit.bind(this)}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}