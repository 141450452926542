import React from 'react';

import { Form, Modal, Table } from 'react-bootstrap';

export default class LogViewerModal extends React.Component {
    constructor() {
        super();

        this.state = {
            levels: [0, 1, 2, 3, 4],
            logs: []
        };
    }

    async componentDidMount() {
        this.updateLogsFromServer();
    }

    async updateLogsFromServer() {
        let logs = await this.props.apiService.getLogs(this.state.levels);

        this.setState({ logs });
    }

    toggleLevel(level) {
        let levels = this.state.levels;

        if (this.state.levels.indexOf(level) === -1)
            levels.push(level);
        else
            levels = this.state.levels.filter(x => x !== level);

        this.setState({ levels }, this.updateLogsFromServer);
    }

    levelAsElement(level) {
        switch (level) {
            case 0:
                return <span style={{color: 'red'}}>Critical</span>;
            case 1:
                return <span style={{color: 'red'}}>Error</span>;
            case 2:
                return <span style={{color: 'orange'}}>Warning</span>;
            case 3:
                return <span style={{color: 'blue'}}>Info</span>;
            case 4:
                return <span>Debug</span>;
            default:
                return <span>Error</span>;
        }
    }

    render() {
        return (
            <Modal size="xl" backdrop="static" show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Log Viewer</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group>
                        <Form.Check
                            inline
                            type='checkbox'
                            label={'Critical'}
                            checked={this.state.levels.indexOf(0) !== -1}
                            onChange={() => { this.toggleLevel(0) }}
                        />
                        <Form.Check 
                            inline
                            type='checkbox'
                            label={'Error'}
                            checked={this.state.levels.indexOf(1) !== -1}
                            onChange={() => { this.toggleLevel(1) }}
                        />
                        <Form.Check 
                            inline
                            type='checkbox'
                            label={'Warning'}
                            checked={this.state.levels.indexOf(2) !== -1}
                            onChange={() => { this.toggleLevel(2) }}
                        />
                        <Form.Check 
                            inline
                            type='checkbox'
                            label={'Info'}
                            checked={this.state.levels.indexOf(3) !== -1}
                            onChange={() => { this.toggleLevel(3) }}
                        />
                        <Form.Check 
                            inline
                            type='checkbox'
                            label={'Debug'}
                            checked={this.state.levels.indexOf(4) !== -1}
                            onChange={() => { this.toggleLevel(4) }}
                        />
                    </Form.Group>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Level</th>
                                <th>Runner</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.logs.map(l => {
                                return (
                                    <tr key={l.Date + (Math.floor(Math.random() * 100000))}>
                                        <td>{l.Date}</td>
                                        <td>{this.levelAsElement(l.Level)}</td>
                                        <td>{l.Runner}</td>
                                        <td><Form.Control size="sm" type="textarea" value={l.Value} disabled={true} /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        );
    }
}
