import React from 'react';
import cURLParser from 'parse-curl';

import 'react-datepicker/dist/react-datepicker.css';

import { Alert, Button, Col, Form, Modal } from 'react-bootstrap';

export default class AddFromCurlModal extends React.Component {
    constructor() {
        super();

        this.state = {
            cURL: '',
            contentType: '',
            date: new Date(),
            parsed: null,
            timesDay: 250,
            error: null
        };
    }

    submit() {
        try {
            let contentType = 'application/x-www-form-urlencoded';
            let parsed = cURLParser(this.state.cURL);

            if (parsed.header['Content-Type']) {
                contentType = parsed.header['Content-Type'];

                delete parsed.header['Content-Type'];
            }

            if (!parsed.body && this.state.cURL.includes('--data-raw')) {
                parsed.method = 'POST';
                parsed.body = this.state.cURL.split('--data-raw \'').pop().split('\'')[0];
            }

            this.props.onCurlParse({
                Request: {
                    URL: parsed.url,
                    Headers: Object.keys(parsed.header).map(name => ({ Name: name, Value: parsed.header[name] })),
                    Method: parsed.method,
                    Content: {
                        Type: contentType,
                        Value: parsed.body
                    }
                },
                TimesPerDay: 250,
                Name: '',
                EndTime: new Date()
            });
        } catch (e) {
            this.setState({ error: true });
        }
    }

    onKeyPressCurl(event) {
        if (event.key === "Enter")
            this.submit();
    }

    render() {
        return (
            <Modal size="xl" backdrop="static" show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Configuration from cURL string</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { this.state.error && (
                        <Alert variant='danger'>
                            <b>Error!</b> Unable to parse <code>cURL</code> data.
                        </Alert>
                    ) }
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>cURL String</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="curl ..." 
                                    value={this.state.cURL} 
                                    onChange={x => this.setState({ cURL: x.target.value })} 
                                    onKeyPress={this.onKeyPressCurl.bind(this)} 
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.submit.bind(this)}>Proceed</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}