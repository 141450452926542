import React from 'react';

import { Alert, Card, Container, Form, Navbar, Spinner } from 'react-bootstrap';

import APIService from '../Services/APIService';

export default class LoginComponent extends React.Component {
    apiService = null;

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            key: '',
            ready: false
        };

        this.init();
    }

    async init() {
        if (await this.tryStoredKey())
            return this.initEnd();

        if (await this.tryNoKey())
            return this.initEnd();
        
        this.initEnd();
    }

    initEnd() {
        this.setState({ ready: true });
    }

    async tryNoKey() {
        try {
            const apiService = new APIService();

            await apiService.verifyKey();

            this.props.onLoginSuccess("");

            return true;
        } catch (e) {
            return false;
        }
    }

    async tryStoredKey() {
        const storedKey = localStorage.getItem('key');

        if (!storedKey || storedKey === '')
            return;

        try {
            const apiService = new APIService(storedKey);

            await apiService.verifyKey();

            this.props.onLoginSuccess(storedKey);

            return true;
        } catch (e) {
            return false;
        }
    }

    async attemptLogin() {
        await this.setState({ error: false });
        
        try {
            const apiService = new APIService(this.state.key);

            await apiService.verifyKey();

            this.props.onLoginSuccess(this.state.key);
        } catch (e) {
            this.setState({ error: true })
        }
    }

    async onKeyPress(event) {
        if (event.key === "Enter")
            await this.attemptLogin();
    }

    render() {
        if (!this.state.ready)
            return (
                <div style={{ position: 'absolute', top: '50%', left: '50%', 'margin-right': '-50%' }}>
                    <Spinner animation='border' size='xl' />
                </div>
            );

        return (
            <>
                <Navbar bg='primary'>
                    <Navbar.Brand>🏃 Request Runner</Navbar.Brand>
                </Navbar>
                <br />
                <Container>
                    { this.state.error && (
                        <Alert variant='danger'>
                            An error has occurred.
                        </Alert>
                    ) }

                    <Card>
                        <Card.Body>
                            <Card.Title><span role="img" aria-label="key">🔑</span> Key Required</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Please type your key to continue.</Card.Subtitle>
                            <hr />
                            <Card.Text>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Secret Key" 
                                    value={this.state.key} 
                                    onChange={x => this.setState({ key: x.target.value })} 
                                    onKeyPress={this.onKeyPress.bind(this) }
                                />
                            </Card.Text>
                            <Card.Link href="#" onClick={this.attemptLogin.bind(this)}>Proceed</Card.Link>
                        </Card.Body>
                    </Card>
                </Container>
            </>
        )
    }
}