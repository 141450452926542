import React from 'react';
import { Button, Container } from 'react-bootstrap';

import MainComponent from './Components/MainComponent';
import LoginComponent from './Components/LoginComponent';

import APIService from './Services/APIService';
import Config from './Config';

class App extends React.Component {
    constructor() {
        super();

        this.state = {
            apiService: null,
            reachable: null,
        };

        this.checkReachability = this.checkReachability.bind(this);

        this.checkReachability();
    }

    async checkReachability() {
        try {
            const response = await fetch(Config.Host);

            this.setState({ reachable: response.status === 404 });
        } catch {
            this.setState({ reachable: false });
        }
    }

    onLoginSuccess(key) {
        localStorage.setItem('key', key);

        this.setState({ apiService: new APIService(key) });
    }

    logout() {
        localStorage.removeItem('key');
        
        this.setState({ apiService: null });
    }

    render() {
        switch (this.state.reachable) {
            case null:
                return <></>;

            case true:
                if (this.state.apiService)
                    return <MainComponent apiService={this.state.apiService} logout={this.logout.bind(this)} />;

                return <LoginComponent onLoginSuccess={this.onLoginSuccess.bind(this)} />;

            case false:
                return (
                    <Container>
                        <br />
                        <h1>⚠️ Service Unreachable</h1>
                        <br />
                        <p>The <b>Request Runner</b> service is unreachable.</p>
                        <p>Are you connected to Tailscale?</p>
                        <br />
                        <Button onClick={this.checkReachability} variant='primary'>Retry</Button>
                    </Container>
                );
        }
    }
}

export default App;
