import React from 'react';
import { Button, ButtonGroup, ButtonToolbar, Container, Navbar, Table } from 'react-bootstrap';

import Moment from 'moment';

import AddConfigurationModal from './AddConfigurationModal';
import AddFromCurlModal from './AddFromCurlModal';
import LogViewerModal from './LogViewerModal';

require('moment/locale/pt');

export default class MainComponent extends React.Component {
    constructor() {
        super();

        Moment.locale('pt');

        this.state = {
            addNewInitialConfiguration: null,
            addNewModalShowing: false,
            addFromCurlModalShowing: false,
            editId: null,
            logViewerModalShowing: false,
            list: [],
            expiredList: []
        };
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const data = await this.props.apiService.getList();
        const expiredData = await this.props.apiService.getExpiredList();

        this.setState({
            list: data,
            expiredList: expiredData
        });
    }

    onAddNewClick() {
        this.setState({
            addNewModalShowing: true,
            addNewInitialConfiguration: null,
            editId: null
        });
    }

    onAddFromCurlClick() {
        this.setState({
            addFromCurlModalShowing: true,
            addNewInitialConfiguration: null,
            editId: null
        });
    }

    onLogViewerClick() {
        this.setState({
            logViewerModalShowing: true
        });
    }

    edit(id) {
        const item = this.state.list.filter(x => x.ID === id)[0];

        this.setState({
            addNewModalShowing: true,
            addNewInitialConfiguration: item,
            editId: id
        });
    }

    async delete(id, expired) {
        await this.props.apiService.deleteConfiguration(id, expired);

        await this.getData();
    }

    onCurlParse(obj) {
        this.setState({
            addFromCurlModalShowing: false,
            addNewInitialConfiguration: obj,
            addNewModalShowing: true
        });
    }

    newFromTemplate(id) {
        const item = this.state.expiredList.filter(x => x.ID === id)[0];

        this.setState({
            addNewInitialConfiguration: item,
            addNewModalShowing: true,
            editId: null
        });
    }

    async onAddConfigurationModalClose() {
        this.setState({
            addNewInitialConfiguration: null,
            addNewModalShowing: false,
            editId: null
        });

        await this.getData();
    }

    async onAddFromCurlModalClose() {
        this.setState({ 
            addFromCurlModalShowing: false 
        });
    }

    async onLogViewerModalClose() {
        this.setState({ 
            logViewerModalShowing: false 
        });

        await this.getData();
    }

    urlToName(url) {
        if (url.length < 35) {
            return url;
        }

        return url.substring(0, 35) + '...';
    }

    render() {
        return (
            <>
                <Navbar bg='primary'>
                    <Navbar.Brand>🏃 Request Runner</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <Button variant='link' onClick={this.props.logout}>Log Out</Button>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Navbar>
                <br />
                <Container>
                    <h1>Active</h1>
                    <br />
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>End Time</th>
                                <th>Times / Day</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.list.sort((a, b) => a.ID - b.ID).map(r => {
                                return (
                                    <tr key={r.ID}>
                                        <td>{r.ID}</td>
                                        { r.Name ? <td><b>{r.Name}</b></td> : <td><b>{this.urlToName(r.Request.URL)}</b></td>}
                                        <td>{Moment(r.EndTime).format('DD/MM/YYYY HH:mm (dddd)')}</td>
                                        <td>~ {r.TimesPerDay}</td>
                                        <td><Button variant="warning" size="sm" onClick={() => { this.edit(r.ID) }}>Edit</Button>&nbsp;<Button variant="danger" size="sm" onClick={() => { this.delete(r.ID, false) }}>Deactivate</Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                    <br />

                    <h1>Inactive</h1>

                    <br />

                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>End Time</th>
                                <th>Times / Day</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.expiredList.sort((a, b) => b.ID - a.ID).map(r => {
                                return (
                                    <tr key={r.ID}>
                                        { r.Name ? <td><b>{r.Name}</b></td> : <td><b>{this.urlToName(r.Request.URL)}</b></td>}
                                        <td>{Moment(r.EndTime).format('DD/MM/YYYY HH:mm (dddd)')}</td>
                                        <td>~ {r.TimesPerDay}</td>
                                        <td><Button variant="primary" size="sm" onClick={() => { this.newFromTemplate(r.ID) }}>Use as Template</Button>&nbsp;<Button variant="danger" size="sm" onClick={() => { this.delete(r.ID, true) }}>Delete</Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                    <br />

                    <ButtonToolbar>
                        <ButtonGroup>
                            <Button variant="dark" onClick={this.onAddNewClick.bind(this)}>📄 Add New</Button>
                            <Button variant="dark" onClick={this.onAddFromCurlClick.bind(this)}>📃 Add New from cURL string</Button>
                        </ButtonGroup>
                        &nbsp;&nbsp;
                        <ButtonGroup>
                            <Button variant="info" onClick={this.onLogViewerClick.bind(this)}>📒 Log Viewer</Button>
                        </ButtonGroup>
                        &nbsp;&nbsp;
                        <ButtonGroup>
                            <Button variant="light" onClick={this.getData.bind(this)}>🔄 Reload Data</Button>
                        </ButtonGroup>
                    </ButtonToolbar>

                    { this.state.addNewModalShowing && <AddConfigurationModal apiService={this.props.apiService} configuration={this.state.addNewInitialConfiguration} editId={this.state.editId} show={this.state.addNewModalShowing} onHide={this.onAddConfigurationModalClose.bind(this)} /> }
                    { this.state.addFromCurlModalShowing && <AddFromCurlModal apiService={this.props.apiService} show={this.state.addFromCurlModalShowing} onHide={this.onAddFromCurlModalClose.bind(this)} onCurlParse={this.onCurlParse.bind(this)} /> }
                    { this.state.logViewerModalShowing && <LogViewerModal apiService={this.props.apiService} show={this.state.logViewerModalShowing} onHide={this.onLogViewerModalClose.bind(this)} /> }

                    <br />
                </Container>
            </>
        );
    }
}
